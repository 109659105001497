











import {CouponsInfo} from "@/request/marketing/CouponsInfo";
import {StoreInfo} from "@/request/merchant/StoreInfo";

export default {
    data() {
        let self: any =this;
        return {
            modal: CouponsInfo,
            search: [
                {
                    type: 'Input',
                    label: '电话',
                    key: 'phone',
                    width: '140px',
                    placeholder: '请填写电话'
                },
                {
                    type: 'Select',
                    label: '状态',
                    key: 'status',
                    clearable: true,
                    width: '120px',
                    items: [
                        {
                            label: '有效',
                            value: 0
                        },
                        {
                            label: '过期',
                            value: 1
                        },
                        {
                            label: '已使用',
                            value: 2
                        }
                    ]
                },
                {
                    type: 'Select',
                    label: '消费店铺',
                    key: 'storeId',
                    clearable: true,
                    width: '120px',
                    items() {
                        return new Promise(resolve => {
                            StoreInfo.getAll().then((body: any) => {
                                let data: any = body.data;
                                resolve(data.map((j: any) => {
                                    return {
                                        label: j.name,
                                        value: j.id
                                    }
                                }));
                            })
                        })
                    }
                }
            ],
            columns: [
                {
                    title: '名称',
                    key: 'name',
                    minWidth: 200
                },
                {
                    title: '金额',
                    key: 'couponFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.couponFee))
                },
                {
                    title: '领取时间',
                    key: 'createTime',
                    minWidth: 180
                },
                {
                    title: '满减起始金额',
                    key: 'fullFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.fullFee))
                },
                {
                    title: '领券开始时间',
                    key: 'getStartTime',
                    minWidth: 120
                },
                {
                    title: '领券结束时间',
                    key: 'getEndTime',
                    minWidth: 120
                },
                {
                    title: '昵称',
                    key: 'nickName',
                    minWidth: 140,
                    tooltip: true
                },
                {
                    title: '电话',
                    key: 'phone',
                    minWidth: 140
                },
                {
                    title: '状态',
                    key: 'status',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.status===0?'有效':p.row.status===1?'过期':'已使用')
                },
                {
                    title: '门店名称',
                    key: 'storeName',
                    minWidth: 120
                },
                {
                    title: '使用渠道',
                    key: 'useChannel',
                    minWidth: 120
                },
                {
                    title: '门店消费说明',
                    key: 'useDesc',
                    minWidth: 120,
                    tooltip: true
                },
                {
                    title: '门店消费金额',
                    key: 'useFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.useFee))
                },
                {
                    title: '使用时间',
                    key: 'useTime',
                    minWidth: 180
                },
                {
                    title: '有效期',
                    minWidth: 180,
                    render: (h: any, p: any) => h('div', {

                    }, [
                        h('p', p.row.validStartTime),
                        h('p', p.row.validEndTime)
                    ])
                }
            ]
        }
    },
    created() {
    },
    methods: {
    }
}
